<template>
  <div>
    <div v-if="packageType == 'Serviços'">
      <div v-if="hotelRows.length > 0 && contractedServices.includes('hotel')">
        <div v-for="(hotel, index) in hotelRows" :key="index">
          <a-row class="collapse-row">
            <a-col :span="24" @click="activeAccordeon(`hotel-${hotel.id}`)">
              <a-row
                class="head"
                :class="
                  tempContract[
                    `sale_hotel_${hotel.id}_taxes_and_incentives_audit_status`
                  ] == 'Realizado'
                    ? 'done'
                    : ''
                "
                type="flex"
                justify="space-between"
              >
                <a-col
                  >HOTEL {{ hotel.id }} -
                  {{ tempContract[`hotel_${hotel.id}_name`] }}
                </a-col>
                <a-col>
                  <img
                    v-if="
                      tempContract[
                        `sale_hotel_${hotel.id}_taxes_and_incentives_audit_status`
                      ] == 'Realizado'
                    "
                    src="@/assets/images/dashboard/sales/check.png"
                    alt="check"
                    width="16"
                  />

                  <a-icon
                    class="ml-10 relative"
                    type="caret-down"
                    style="top: 3px"
                  />
                </a-col>
              </a-row>
            </a-col>

            <a-col
              v-if="activeCollapse == `hotel-${hotel.id}`"
              class="content"
              :span="24"
            >
              <HotelIncentivesTab
                :tempContract="tempContract"
                :sale="sale"
                :hotel="hotel"
                @reloadSale="reloadSale"
              />
            </a-col>
          </a-row>
        </div>
      </div>

      <div
        v-if="
          flightSectionsRows.length > 0 && contractedServices.includes('flight')
        "
      >
        <div v-for="(flight, index) in flightSectionsRows" :key="index">
          <a-row class="collapse-row">
            <a-col :span="24" @click="activeAccordeon(`flight-${flight.id}`)">
              <a-row
                class="head"
                :class="
                  tempContract[
                    `sale_flight_${flight.id}_taxes_and_incentives_audit_status`
                  ] == 'Realizado'
                    ? 'done'
                    : ''
                "
                type="flex"
                justify="space-between"
              >
                <a-col
                  >AÉREO {{ flight.id }} -
                  {{ tempContract[`flight_${flight.id}_type`] }}
                </a-col>
                <a-col>
                  <img
                    v-if="
                      tempContract[
                        `sale_flight_${flight.id}_taxes_and_incentives_audit_status`
                      ] == 'Realizado'
                    "
                    src="@/assets/images/dashboard/sales/check.png"
                    alt="check"
                    width="16"
                  />

                  <a-icon
                    class="ml-10 relative"
                    type="caret-down"
                    style="top: 3px"
                  />
                </a-col>
              </a-row>
            </a-col>

            <a-col
              v-if="activeCollapse == `flight-${flight.id}`"
              class="content"
              :span="24"
            >
              <FlightIncentivesTab
                :tempContract="tempContract"
                :sale="sale"
                :flight="flight"
                @reloadSale="reloadSale"
              />
            </a-col>
          </a-row>
        </div>
      </div>

      <div
        v-if="serviceRows.length > 0 && contractedServices.includes('service')"
      >
        <div v-for="(service, index) in serviceRows" :key="index">
          <a-row class="collapse-row">
            <a-col :span="24" @click="activeAccordeon(`service-${service.id}`)">
              <a-row
                class="head"
                :class="
                  tempContract[
                    `sale_service_${service.id}_taxes_and_incentives_audit_status`
                  ] == 'Realizado'
                    ? 'done'
                    : ''
                "
                type="flex"
                justify="space-between"
              >
                <a-col
                  >SERVIÇO {{ service.id }} -
                  {{ tempContract[`service_${service.id}_type`] }}
                </a-col>
                <a-col>
                  <img
                    v-if="
                      tempContract[
                        `sale_service_${service.id}_taxes_and_incentives_audit_status`
                      ] == 'Realizado'
                    "
                    src="@/assets/images/dashboard/sales/check.png"
                    alt="check"
                    width="16"
                  />

                  <a-icon
                    class="ml-10 relative"
                    type="caret-down"
                    style="top: 3px"
                  />
                </a-col>
              </a-row>
            </a-col>

            <a-col
              v-if="activeCollapse == `service-${service.id}`"
              class="content"
              :span="24"
            >
              <ServiceIncentivesTab
                :tempContract="tempContract"
                :sale="sale"
                :service="service"
                @reloadSale="reloadSale"
              />
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div v-if="packageType == 'Pacote Completo Terceiros'">
      <a-row class="collapse-row">
        <a-col :span="24" @click="activeAccordeon(`package`)">
          <a-row
            class="head"
            :class="
              tempContract[`sale_package_taxes_and_incentives_audit_status`] ==
              'Realizado'
                ? 'done'
                : ''
            "
            type="flex"
            justify="space-between"
          >
            <a-col>PACOTE COMPLETO </a-col>
            <a-col>
              <img
                v-if="
                  tempContract[
                    `sale_package_taxes_and_incentives_audit_status`
                  ] == 'Realizado'
                "
                src="@/assets/images/dashboard/sales/check.png"
                alt="check"
                width="16"
              />

              <a-icon
                class="ml-10 relative"
                type="caret-down"
                style="top: 3px"
              />
            </a-col>
          </a-row>
        </a-col>

        <a-col v-if="activeCollapse == `package`" class="content" :span="24">
          <PackageIncentivesTab
            :tempContract="tempContract"
            :sale="sale"
            @reloadSale="reloadSale"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import HotelIncentivesTab from "./HotelIncentivesTab.vue";
import FlightIncentivesTab from "./FlightIncentivesTab.vue";
import ServiceIncentivesTab from "./ServiceIncentivesTab.vue";
import PackageIncentivesTab from "./PackageIncentivesTab.vue";

export default {
  components: {
    HotelIncentivesTab,
    FlightIncentivesTab,
    ServiceIncentivesTab,
    PackageIncentivesTab,
  },
  props: {
    tempContract: Object,
    sale: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      activeCollapse: "",
      hotelRows: [],
      serviceRows: [],
      flightSectionsRows: [],
      packageType: "",
      contractedServices: [],
    };
  },
  mounted() {
    this.packageType = this.tempContract.package_type;

    this.contractedServices = this.tempContract.contracted_services
      ? JSON.parse(this.tempContract.contracted_services)
      : [];

    this.hotelRows = this.tempContract.hotel_rows
      ? JSON.parse(this.tempContract.hotel_rows)
      : [];

    this.flightSectionsRows = this.tempContract.flight_sections_rows
      ? JSON.parse(this.tempContract.flight_sections_rows)
      : [];

    this.serviceRows = this.tempContract.service_rows
      ? JSON.parse(this.tempContract.service_rows)
      : [];
  },
  methods: {
    activeAccordeon(current) {
      if (current == this.activeCollapse) {
        this.activeCollapse = -1;
      } else {
        this.activeCollapse = current;
      }
    },
    reloadSale() {
      this.$emit("reloadSale");
    },
  },
};
</script>

<style lang="sass" scoped>
.collapse-row
  padding: 0 10px 0 0
  .content
    padding: 6px 3px 20px 20px
    .edit
      background: #f27935
      border-color: #f27935
      padding: 5px 10px
    .update
      background: #2ecc71
      border-color: #2ecc71
      padding: 5px 10px
  .head
    text-transform: capitalize
    background: #929496
    padding: 5px 8px
    border-radius: 5px
    margin-bottom: 5px
    color: #FFF
    text-transform: uppercase
    cursor: pointer
    &.done
      background: #2ecc71
.sale-tabs
  .tab
    max-height: 590px
    overflow: auto
    overflow-x: hidden
  .tabs-content
    padding: 0 10px 10px 10px
    h2
      padding: 0 0 8px 0
      border-bottom: 1px solid #ebebeb
      font-size: 18px
  .tabs-side
    text-align: center
    min-height: 600px
    border-right: 1px solid #ebebeb
    .tab
      padding: 15px 10px
      cursor: pointer
      transition: .3s
      opacity: 0.5
      filter: grayscale(1)
    .tab.active, .tab:hover
      opacity: 1
      transition: .3s
      filter: grayscale(0)
</style>
