<template>
  <div>
    <a-form :form="form" @submit="submitForm">
      <a-row>
        <a-col :span="24">
          <h4 class="subtitile">
            <a-row type="flex" justify="space-between">
              <a-col> <span> Taxas Extras </span></a-col>
              <a-col>
                <a-button
                  v-if="edit"
                  class="add-button color"
                  size="small"
                  @click="addTaxRow()"
                >
                  Adicionar
                </a-button></a-col
              >
            </a-row>
          </h4>
          <a-row v-for="(tax, index) in taxesRows" :gutter="20" :key="index">
            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label class="filled">Taxas (R$)</label>
                <a-input
                  class="travel-input"
                  placeholder="Taxas (R$)"
                  v-currency
                  :disabled="!edit"
                  v-decorator="[
                    `sale_service_${service.id}_tax_${tax.id}_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item class="travel-input-outer travel-multiselector">
                <label :class="'filled'"> Tipo </label>

                <a-select
                  class="travel-input"
                  show-search
                  :allow-clear="true"
                  optionFilterProp="txt"
                  :disabled="!edit"
                  placeholder="Selecione um tipo"
                  v-decorator="[
                    `sale_service_${service.id}_tax_${tax.id}_type`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="(item, index) of taxesType"
                    :key="index"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item>
                <a-checkbox
                  :checked="taxIsChecked(tax.id)"
                  v-decorator="[
                    `sale_service_${service.id}_tax_${tax.id}_subtract_in_profit`,
                  ]"
                  :disabled="!edit"
                  ><font class="f10"
                    >(-) Descontar na lucratividade</font
                  ></a-checkbox
                >
              </a-form-item>
            </a-col>

            <a-col v-if="edit" :span="1">
              <a-button
                v-if="tax.id == taxesRows.length"
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeTaxRow(tax.id)"
              >
              </a-button>
            </a-col>
          </a-row>
          <div class="f12 mb-20" v-if="!taxesRows.length">
            Nenhum taxa extra.
          </div>
        </a-col>

        <a-col :span="24">
          <h4 class="subtitile">
            <a-row type="flex" justify="space-between">
              <a-col> <span> Incentivos</span></a-col>
              <a-col>
                <a-button
                  class="add-button color"
                  size="small"
                  @click="addIncentiveRow()"
                  v-if="edit"
                >
                  Adicionar
                </a-button></a-col
              >
            </a-row>
          </h4>

          <a-row
            v-for="(incentive, index) in incentivesRows"
            :gutter="20"
            :key="index"
          >
            <a-col :span="5">
              <a-form-item class="travel-input-outer travel-multiselector">
                <label :class="'filled'"> Tipo </label>

                <a-select
                  class="travel-input"
                  show-search
                  :allow-clear="true"
                  optionFilterProp="txt"
                  placeholder="% ou R$"
                  :disabled="!edit"
                  v-decorator="[
                    `sale_service_${service.id}_incentive_${incentive.id}_type`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  style="width: 100%"
                >
                  <a-select-option value="%"> % </a-select-option>
                  <a-select-option value="R$"> R$ </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-row :gutter="8">
                <a-col :span="24">
                  <a-form-item class="travel-input-outer">
                    <label
                      class="filled"
                      v-show="
                        form.getFieldValue(
                          `sale_service_${service.id}_incentive_${incentive.id}_type`
                        ) == 'R$'
                      "
                      >Valor incentivo</label
                    >

                    <label
                      class="filled"
                      v-show="
                        form.getFieldValue(
                          `sale_service_${service.id}_incentive_${incentive.id}_type`
                        ) == '%'
                      "
                      >Porcentagem incentivo</label
                    >
                    <a-input
                      class="travel-input"
                      placeholder="Valor incentivo"
                      v-currency="{
                        currency: {
                          prefix: '',
                          suffix: '',
                        },
                        distractionFree: true,
                        valueAsInteger: false,
                        precision: 2,
                        autoDecimalMode: true,
                        allowNegative: false,
                      }"
                      v-decorator="[
                        `sale_service_${service.id}_incentive_${incentive.id}_value`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                      :disabled="!edit"
                      @blur="calcIncetivesPercentage(service.id, incentive.id)"
                    >
                      <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>

            <a-col
              v-show="
                form.getFieldValue(
                  `sale_service_${service.id}_incentive_${incentive.id}_type`
                ) == '%'
              "
              :span="4"
            >
              <a-form-item class="travel-input-outer">
                <label class="filled">Valor (R$)</label>
                <a-input
                  class="travel-input"
                  placeholder="Valor (R$)"
                  v-currency
                  :disabled="true"
                  v-decorator="[
                    `sale_service_${service.id}_incentive_${incentive.id}_percentage_value`,
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item>
                <a-checkbox
                  :checked="incentiveIsChecked(incentive.id)"
                  v-decorator="[
                    `sale_service_${service.id}_incentive_${incentive.id}_sum_in_profit`,
                  ]"
                  :disabled="!edit"
                  ><font class="f10"
                    >(+) Somar na lucratividade</font
                  ></a-checkbox
                >
              </a-form-item>
            </a-col>

            <a-col v-if="edit" :span="3">
              <a-button
                v-if="incentive.id == incentivesRows.length"
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeIncentiveRow(incentive.id)"
              >
              </a-button>
            </a-col>
          </a-row>
          <div class="f12 mb-20" v-if="!incentivesRows.length">
            Nenhum incentivo extra.
          </div>
        </a-col>

        <a-col
          v-if="
            tempContract[
              `sale_service_${service.id}_taxes_and_incentives_audit_status`
            ] != 'Realizado'
          "
          class="actions"
          :span="24"
        >
          <a-button
            v-if="edit"
            @click="edit = false"
            class="ml-10 cancel"
            type="primary"
          >
            <a-icon type="close" />
          </a-button>

          <a-button
            v-if="!edit"
            @click="edit = true"
            class="ml-10 edit"
            type="primary"
          >
            <a-icon type="edit" />
          </a-button>

          <a-button
            v-if="edit"
            html-type="submit"
            class="ml-10 save"
            type="primary"
            style="padding: 0px 6px"
            :loading="loading"
          >
            <img
              v-show="!loading"
              src="@/assets/images/dashboard/sales/disket.png"
              alt="save"
            />
          </a-button>

          <a-popconfirm
            placement="left"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="confirmAuditory"
          >
            <template slot="title">
              <p>
                Você confirma todos os valores e dados
                <br />inseridos? Após esta ação não será mais
                <br />
                possível editar.
              </p>
            </template>
            <a-button
              v-if="!edit"
              html-type="submit"
              class="ml-10 update"
              type="primary"
            >
              <a-icon type="check" />
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  props: {
    tempContract: Object,
    sale: Object,
    service: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      form: this.$form.createForm(this),
      taxesRows: [],
      incentivesRows: [],
      taxesType: [],
      edit: false,
      loading: false,
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=3&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.taxesType = data.data;
      });

    this.taxesRows = this.tempContract[
      `sale_service_${this.service.id}_taxes_rows`
    ]
      ? JSON.parse(
          this.tempContract[`sale_service_${this.service.id}_taxes_rows`]
        )
      : [];

    this.incentivesRows = this.tempContract[
      `sale_service_${this.service.id}_incentives_rows`
    ]
      ? JSON.parse(
          this.tempContract[`sale_service_${this.service.id}_incentives_rows`]
        )
      : [];

    setTimeout(() => {
      this.taxesRows.forEach((tax) => {
        this.form.setFieldsValue({
          [`sale_service_${this.service.id}_tax_${tax.id}_value`]:
            this.tempContract[
              `sale_service_${this.service.id}_tax_${tax.id}_value`
            ],
          [`sale_service_${this.service.id}_tax_${tax.id}_type`]:
            this.tempContract[
              `sale_service_${this.service.id}_tax_${tax.id}_type`
            ],
          [`sale_service_${this.service.id}_tax_${tax.id}_subtract_in_profit`]:
            this.tempContract[
              `sale_service_${this.service.id}_tax_${tax.id}_subtract_in_profit`
            ] == true &&
            this.tempContract[
              `sale_service_${this.service.id}_tax_${tax.id}_subtract_in_profit`
            ] == 1
              ? true
              : false,
        });
      });

      this.incentivesRows.forEach((i) => {
        this.form.setFieldsValue({
          [`sale_service_${this.service.id}_incentive_${i.id}_value`]:
            this.tempContract[
              `sale_service_${this.service.id}_incentive_${i.id}_value`
            ],
          [`sale_service_${this.service.id}_incentive_${i.id}_type`]:
            this.tempContract[
              `sale_service_${this.service.id}_incentive_${i.id}_type`
            ],
          [`sale_service_${this.service.id}_incentive_${i.id}_percentage_value`]:
            this.tempContract[
              `sale_service_${this.service.id}_incentive_${i.id}_percentage_value`
            ],

          [`sale_service_${this.service.id}_incentive_${i.id}_sum_in_profit`]:
            this.tempContract[
              `sale_service_${this.service.id}_incentive_${i.id}_sum_in_profit`
            ] == true &&
            this.tempContract[
              `sale_service_${this.service.id}_incentive_${i.id}_sum_in_profit`
            ] == 1
              ? true
              : false,
        });
      });
    }, 100);
  },
  methods: {
    calcIncetivesPercentage(id, incentiveId) {
      let percentage = 0,
        totalValue = 0;

      percentage = this.form.getFieldValue(
        `sale_service_${id}_incentive_${incentiveId}_value`
      );

      if (percentage) {
        percentage = parseFloat(percentage.replace(",", "."));
        percentage = percentage / 100;
      } else {
        percentage = 0;
      }

      totalValue = this.tempContract[`service_${id}_total_price`];

      if (totalValue) {
        totalValue = parseFloat(totalValue.replace(",", "."));
      } else {
        totalValue = 0;
      }

      if (totalValue && percentage) {
        let thePercentageVal = (percentage * totalValue).toFixed(2);

        this.form.setFieldsValue({
          [`sale_service_${id}_incentive_${incentiveId}_percentage_value`]:
            thePercentageVal.replace(".", ","),
        });
      }
    },
    taxIsChecked(id) {
      let flag = false;

      if (
        this.form.getFieldValue(
          `sale_service_${this.service.id}_tax_${id}_subtract_in_profit`
        ) != undefined
      ) {
        flag =
          this.form.getFieldValue(
            `sale_service_${this.service.id}_tax_${id}_subtract_in_profit`
          ) == 1
            ? true
            : false;
      }

      return flag;
    },
    incentiveIsChecked(id) {
      let flag = false;

      if (
        this.form.getFieldValue(
          `sale_service_${this.service.id}_incentive_${id}_sum_in_profit`
        ) != undefined
      ) {
        flag =
          this.form.getFieldValue(
            `sale_service_${this.service.id}_incentive_${id}_sum_in_profit`
          ) == 1
            ? true
            : false;
      }

      return flag;
    },
    addTaxRow() {
      setTimeout(() => {
        this.taxesRows.push({
          id: this.taxesRows.length + 1,
        });
      }, 100);
    },
    removeTaxRow(id) {
      for (var i in this.taxesRows) {
        if (this.taxesRows[i].id == id) {
          this.taxesRows.splice(i, 1);
          break;
        }
      }
    },
    addIncentiveRow() {
      setTimeout(() => {
        this.incentivesRows.push({
          id: this.incentivesRows.length + 1,
        });
      }, 100);
    },
    removeIncentiveRow(id) {
      for (var i in this.incentivesRows) {
        if (this.incentivesRows[i].id == id) {
          this.incentivesRows.splice(i, 1);
          break;
        }
      }
    },
    confirmAuditory() {
      let values = {};

      values[
        `sale_service_${this.service.id}_taxes_and_incentives_audit_status`
      ] = "Realizado";
      values[
        `sale_service_${this.service.id}_taxes_and_incentives_audit_user_id`
      ] = this.$store.state.userData.id;

      values.id = this.tempContract.id;
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("reloadSale");
          this.edit = false;

          this.$http
            .post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.sale.id,
              module: "Sale",
              action: "update-incentives-and-taxes-in-sale",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} confirmou através de auditoria os dados do valores das taxas e incentivos na venda #${this.sale.id}.`,
            })
            .then(({ data }) => {
              data;
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    submitForm(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        values.id = this.tempContract.id;
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };
        values[`sale_service_${this.service.id}_taxes_rows`] = JSON.stringify(
          this.taxesRows
        );
        values[`sale_service_${this.service.id}_incentives_rows`] =
          JSON.stringify(this.incentivesRows);

        if (!err) {
          this.loading = true;
          this.$http
            .post("/contract/update-multi-meta", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("reloadSale");
              this.edit = false;
              this.loading = false;

              this.$http
                .post("/log/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: this.sale.id,
                  module: "Sale",
                  action: "update-incentives-and-taxes-in-sale",
                  description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou valores das taxas e incentivos na venda #${this.sale.id}.`,
                })
                .then(({ data }) => {
                  data;
                });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        } else {
          this.$message.error("Prrencha todos os campos.");
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.actions
  text-align: right
  border-top: 1px solid #eee
  padding: 10px 0 0 0
  margin-top: 20px
  .edit
    background: #f27935
    border-color: #f27935
    padding: 5px 10px
  .cancel
    background: #59595b
    border-color: #59595b
    padding: 5px 10px
  .update
    background: #2ecc71
    border-color: #2ecc71
    padding: 5px 10px
    width: 32px
  .save
    background: #00acec
    border-color: #00acec
    padding: 0 6px
    width: 32px
    text-align: center

.subtitile
  text-transform: uppercase
  font-size: 11px
  padding: 5px 0
  border-bottom: 1px solid #ddd
  margin-bottom: 20px
  button
    position: relative
    margin-top: -7px
</style>
