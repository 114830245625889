var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.packageType == 'Serviços')?_c('div',[(_vm.hotelRows.length > 0 && _vm.contractedServices.includes('hotel'))?_c('div',_vm._l((_vm.hotelRows),function(hotel,index){return _c('div',{key:index},[_c('a-row',{staticClass:"collapse-row"},[_c('a-col',{attrs:{"span":24},on:{"click":function($event){return _vm.activeAccordeon(`hotel-${hotel.id}`)}}},[_c('a-row',{staticClass:"head",class:_vm.tempContract[
                  `sale_hotel_${hotel.id}_taxes_and_incentives_audit_status`
                ] == 'Realizado'
                  ? 'done'
                  : '',attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("HOTEL "+_vm._s(hotel.id)+" -\n                "+_vm._s(_vm.tempContract[`hotel_${hotel.id}_name`])+"\n              ")]),_vm._v(" "),_c('a-col',[(
                    _vm.tempContract[
                      `sale_hotel_${hotel.id}_taxes_and_incentives_audit_status`
                    ] == 'Realizado'
                  )?_c('img',{attrs:{"src":require("@/assets/images/dashboard/sales/check.png"),"alt":"check","width":"16"}}):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"ml-10 relative",staticStyle:{"top":"3px"},attrs:{"type":"caret-down"}})],1)],1)],1),_vm._v(" "),(_vm.activeCollapse == `hotel-${hotel.id}`)?_c('a-col',{staticClass:"content",attrs:{"span":24}},[_c('HotelIncentivesTab',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"hotel":hotel},on:{"reloadSale":_vm.reloadSale}})],1):_vm._e()],1)],1)}),0):_vm._e(),_vm._v(" "),(
        _vm.flightSectionsRows.length > 0 && _vm.contractedServices.includes('flight')
      )?_c('div',_vm._l((_vm.flightSectionsRows),function(flight,index){return _c('div',{key:index},[_c('a-row',{staticClass:"collapse-row"},[_c('a-col',{attrs:{"span":24},on:{"click":function($event){return _vm.activeAccordeon(`flight-${flight.id}`)}}},[_c('a-row',{staticClass:"head",class:_vm.tempContract[
                  `sale_flight_${flight.id}_taxes_and_incentives_audit_status`
                ] == 'Realizado'
                  ? 'done'
                  : '',attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("AÉREO "+_vm._s(flight.id)+" -\n                "+_vm._s(_vm.tempContract[`flight_${flight.id}_type`])+"\n              ")]),_vm._v(" "),_c('a-col',[(
                    _vm.tempContract[
                      `sale_flight_${flight.id}_taxes_and_incentives_audit_status`
                    ] == 'Realizado'
                  )?_c('img',{attrs:{"src":require("@/assets/images/dashboard/sales/check.png"),"alt":"check","width":"16"}}):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"ml-10 relative",staticStyle:{"top":"3px"},attrs:{"type":"caret-down"}})],1)],1)],1),_vm._v(" "),(_vm.activeCollapse == `flight-${flight.id}`)?_c('a-col',{staticClass:"content",attrs:{"span":24}},[_c('FlightIncentivesTab',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"flight":flight},on:{"reloadSale":_vm.reloadSale}})],1):_vm._e()],1)],1)}),0):_vm._e(),_vm._v(" "),(_vm.serviceRows.length > 0 && _vm.contractedServices.includes('service'))?_c('div',_vm._l((_vm.serviceRows),function(service,index){return _c('div',{key:index},[_c('a-row',{staticClass:"collapse-row"},[_c('a-col',{attrs:{"span":24},on:{"click":function($event){return _vm.activeAccordeon(`service-${service.id}`)}}},[_c('a-row',{staticClass:"head",class:_vm.tempContract[
                  `sale_service_${service.id}_taxes_and_incentives_audit_status`
                ] == 'Realizado'
                  ? 'done'
                  : '',attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("SERVIÇO "+_vm._s(service.id)+" -\n                "+_vm._s(_vm.tempContract[`service_${service.id}_type`])+"\n              ")]),_vm._v(" "),_c('a-col',[(
                    _vm.tempContract[
                      `sale_service_${service.id}_taxes_and_incentives_audit_status`
                    ] == 'Realizado'
                  )?_c('img',{attrs:{"src":require("@/assets/images/dashboard/sales/check.png"),"alt":"check","width":"16"}}):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"ml-10 relative",staticStyle:{"top":"3px"},attrs:{"type":"caret-down"}})],1)],1)],1),_vm._v(" "),(_vm.activeCollapse == `service-${service.id}`)?_c('a-col',{staticClass:"content",attrs:{"span":24}},[_c('ServiceIncentivesTab',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale,"service":service},on:{"reloadSale":_vm.reloadSale}})],1):_vm._e()],1)],1)}),0):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.packageType == 'Pacote Completo Terceiros')?_c('div',[_c('a-row',{staticClass:"collapse-row"},[_c('a-col',{attrs:{"span":24},on:{"click":function($event){return _vm.activeAccordeon(`package`)}}},[_c('a-row',{staticClass:"head",class:_vm.tempContract[`sale_package_taxes_and_incentives_audit_status`] ==
            'Realizado'
              ? 'done'
              : '',attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_vm._v("PACOTE COMPLETO ")]),_vm._v(" "),_c('a-col',[(
                _vm.tempContract[
                  `sale_package_taxes_and_incentives_audit_status`
                ] == 'Realizado'
              )?_c('img',{attrs:{"src":require("@/assets/images/dashboard/sales/check.png"),"alt":"check","width":"16"}}):_vm._e(),_vm._v(" "),_c('a-icon',{staticClass:"ml-10 relative",staticStyle:{"top":"3px"},attrs:{"type":"caret-down"}})],1)],1)],1),_vm._v(" "),(_vm.activeCollapse == `package`)?_c('a-col',{staticClass:"content",attrs:{"span":24}},[_c('PackageIncentivesTab',{attrs:{"tempContract":_vm.tempContract,"sale":_vm.sale},on:{"reloadSale":_vm.reloadSale}})],1):_vm._e()],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }