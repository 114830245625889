import { render, staticRenderFns } from "./HotelIncentivesTab.vue?vue&type=template&id=6d513c9d&scoped=true&"
import script from "./HotelIncentivesTab.vue?vue&type=script&lang=js&"
export * from "./HotelIncentivesTab.vue?vue&type=script&lang=js&"
import style0 from "./HotelIncentivesTab.vue?vue&type=style&index=0&id=6d513c9d&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d513c9d",
  null
  
)

export default component.exports