var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submitForm}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('span',[_vm._v(" Taxas Extras ")])]),_vm._v(" "),_c('a-col',[(_vm.edit)?_c('a-button',{staticClass:"add-button color",attrs:{"size":"small"},on:{"click":_vm.addTaxRow}},[_vm._v("\n                Adicionar\n              ")]):_vm._e()],1)],1)],1),_vm._v(" "),_vm._l((_vm.taxesRows),function(tax,index){return _c('a-row',{key:index,attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Taxas (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `sale_package_tax_${tax.id}_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `sale_package_tax_${tax.id}_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Taxas (R$)","disabled":!_vm.edit}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Tipo ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `sale_package_tax_${tax.id}_type`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `sale_package_tax_${tax.id}_type`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","disabled":!_vm.edit,"placeholder":"Selecione um tipo"}},_vm._l((_vm.taxesType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v("\n                  "+_vm._s(item.name)+"\n                ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `sale_package_tax_${tax.id}_subtract_in_profit`,
                ]),expression:"[\n                  `sale_package_tax_${tax.id}_subtract_in_profit`,\n                ]"}],attrs:{"checked":_vm.taxIsChecked(tax.id),"disabled":!_vm.edit}},[_c('font',{staticClass:"f10"},[_vm._v("(-) Descontar na lucratividade")])],1)],1)],1),_vm._v(" "),(_vm.edit)?_c('a-col',{attrs:{"span":1}},[(tax.id == _vm.taxesRows.length)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeTaxRow(tax.id)}}}):_vm._e()],1):_vm._e()],1)}),_vm._v(" "),(!_vm.taxesRows.length)?_c('div',{staticClass:"f12 mb-20"},[_vm._v("\n          Nenhum taxa extra.\n        ")]):_vm._e()],2),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',[_c('span',[_vm._v(" Incentivos")])]),_vm._v(" "),_c('a-col',[(_vm.edit)?_c('a-button',{staticClass:"add-button color",attrs:{"size":"small"},on:{"click":_vm.addIncentiveRow}},[_vm._v("\n                Adicionar\n              ")]):_vm._e()],1)],1)],1),_vm._v(" "),_vm._l((_vm.incentivesRows),function(incentive,index){return _c('a-row',{key:index,attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer travel-multiselector"},[_c('label',{class:'filled'},[_vm._v(" Tipo ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `sale_package_incentive_${incentive.id}_type`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `sale_package_incentive_${incentive.id}_type`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"show-search":"","allow-clear":true,"optionFilterProp":"txt","placeholder":"% ou R$","disabled":!_vm.edit}},[_c('a-select-option',{attrs:{"value":"%"}},[_vm._v(" % ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"R$"}},[_vm._v(" R$ ")])],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.form.getFieldValue(
                        `sale_package_incentive_${incentive.id}_type`
                      ) == 'R$'
                    ),expression:"\n                      form.getFieldValue(\n                        `sale_package_incentive_${incentive.id}_type`\n                      ) == 'R$'\n                    "}],staticClass:"filled"},[_vm._v("Valor incentivo")]),_vm._v(" "),_c('label',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.form.getFieldValue(
                        `sale_package_incentive_${incentive.id}_type`
                      ) == '%'
                    ),expression:"\n                      form.getFieldValue(\n                        `sale_package_incentive_${incentive.id}_type`\n                      ) == '%'\n                    "}],staticClass:"filled"},[_vm._v("Porcentagem incentivo")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                      currency: {
                        prefix: '',
                        suffix: '',
                      },
                      distractionFree: true,
                      valueAsInteger: false,
                      precision: 2,
                      autoDecimalMode: true,
                      allowNegative: false,
                    }),expression:"{\n                      currency: {\n                        prefix: '',\n                        suffix: '',\n                      },\n                      distractionFree: true,\n                      valueAsInteger: false,\n                      precision: 2,\n                      autoDecimalMode: true,\n                      allowNegative: false,\n                    }"},{name:"decorator",rawName:"v-decorator",value:([
                      `sale_package_incentive_${incentive.id}_value`,
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      `sale_package_incentive_${incentive.id}_value`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor incentivo","disabled":!_vm.edit},on:{"blur":function($event){return _vm.calcIncetivesPercentage(incentive.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.form.getFieldValue(
                `sale_package_incentive_${incentive.id}_type`
              ) == '%'
            ),expression:"\n              form.getFieldValue(\n                `sale_package_incentive_${incentive.id}_type`\n              ) == '%'\n            "}],attrs:{"span":4}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Valor (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `sale_package_incentive_${incentive.id}_percentage_value`,
                ]),expression:"[\n                  `sale_package_incentive_${incentive.id}_percentage_value`,\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor (R$)","disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `sale_package_incentive_${incentive.id}_sum_in_profit`,
                ]),expression:"[\n                  `sale_package_incentive_${incentive.id}_sum_in_profit`,\n                ]"}],attrs:{"checked":_vm.incentiveIsChecked(incentive.id),"disabled":!_vm.edit}},[_c('font',{staticClass:"f10"},[_vm._v("(+) Somar na lucratividade")])],1)],1)],1),_vm._v(" "),(_vm.edit)?_c('a-col',{attrs:{"span":3}},[(incentive.id == _vm.incentivesRows.length)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeIncentiveRow(incentive.id)}}}):_vm._e()],1):_vm._e()],1)}),_vm._v(" "),(!_vm.incentivesRows.length)?_c('div',{staticClass:"f12 mb-20"},[_vm._v("\n          Nenhum incentivo extra.\n        ")]):_vm._e()],2),_vm._v(" "),(
          _vm.tempContract[`sale_package_taxes_and_incentives_audit_status`] !=
          'Realizado'
        )?_c('a-col',{staticClass:"actions",attrs:{"span":24}},[(_vm.edit)?_c('a-button',{staticClass:"ml-10 cancel",attrs:{"type":"primary"},on:{"click":function($event){_vm.edit = false}}},[_c('a-icon',{attrs:{"type":"close"}})],1):_vm._e(),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 edit",attrs:{"type":"primary"},on:{"click":function($event){_vm.edit = true}}},[_c('a-icon',{attrs:{"type":"edit"}})],1):_vm._e(),_vm._v(" "),(_vm.edit)?_c('a-button',{staticClass:"ml-10 save",staticStyle:{"padding":"0px 6px"},attrs:{"html-type":"submit","type":"primary","loading":_vm.loading}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"src":require("@/assets/images/dashboard/sales/disket.png"),"alt":"save"}})]):_vm._e(),_vm._v(" "),_c('a-popconfirm',{attrs:{"placement":"left","ok-text":"Sim","cancel-text":"Não"},on:{"confirm":_vm.confirmAuditory}},[_c('template',{slot:"title"},[_c('p',[_vm._v("\n              Você confirma todos os valores e dados\n              "),_c('br'),_vm._v("inseridos? Após esta ação não será mais\n              "),_c('br'),_vm._v("\n              possível editar.\n            ")])]),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 update",attrs:{"html-type":"submit","type":"primary"}},[_c('a-icon',{attrs:{"type":"check"}})],1):_vm._e()],2)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }